import { useEffect } from 'react';

const MetadataCollector = () => {
  useEffect(() => {
    console.log('Metadata collection script is running...');

    const metadata = {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        languages: navigator.languages,
        vendor: navigator.vendor,
        cookieEnabled: navigator.cookieEnabled,
        hardwareConcurrency: navigator.hardwareConcurrency,
        maxTouchPoints: navigator.maxTouchPoints,
        deviceMemory: navigator.deviceMemory || 'Unknown',
        product: navigator.product,
        doNotTrack: navigator.doNotTrack,
        screenSize: `${window.screen.width}x${window.screen.height}`, // Updated
        viewportSize: `${window.innerWidth}x${window.innerHeight}`,
        pixelRatio: window.devicePixelRatio,
        colorDepth: window.screen.colorDepth, // Updated
        availableScreenSize: `${window.screen.availWidth}x${window.screen.availHeight}`, // Updated
        referrer: document.referrer,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        locale: Intl.DateTimeFormat().resolvedOptions().locale,
        timestamp: new Date().toISOString(),
        connection: navigator.connection
          ? {
              effectiveType: navigator.connection.effectiveType,
              downlink: navigator.connection.downlink,
              rtt: navigator.connection.rtt,
              saveData: navigator.connection.saveData,
            }
          : 'Unknown',
        historyLength: window.history.length,
      };
      

    if (navigator.getBattery) {
      navigator.getBattery().then((battery) => {
        metadata.battery = {
          level: battery.level,
          charging: battery.charging,
          chargingTime: battery.chargingTime,
          dischargingTime: battery.dischargingTime,
        };

        console.log('Collected metadata with battery info:', metadata);

        // Send metadata to the Netlify function
        fetch('/.netlify/functions/collect-metadata', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(metadata),
        })
          .then((response) => {
            console.log('Metadata sent successfully:', response.status);
          })
          .catch((error) => {
            console.error('Error sending metadata:', error);
          });
      });
    } else {
      console.log('Collected metadata without battery info:', metadata);

      // Send metadata to the Netlify function
      fetch('/.netlify/functions/collect-metadata', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(metadata),
      })
        .then((response) => {
          console.log('Metadata sent successfully:', response.status);
        })
        .catch((error) => {
          console.error('Error sending metadata:', error);
        });
    }
  }, []);

  return null; // This component doesn't render anything
};

export default MetadataCollector;
