import React, { useState, useEffect } from 'react';
import { Modal, Button, Typography, Box } from '@mui/material';

const CookieBanner = () => {
    const [open, setOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem('cookieConsent');
        if (!consentGiven) {
            setOpen(true);
        }
    }, []);

    const handleAccept = () => {
        setOpen(false);
        localStorage.setItem('cookieConsent', 'true');
    };

    const handleOpenTerms = () => {
        setTermsOpen(true);
    };

    const handleCloseTerms = () => {
        setTermsOpen(false);
    };

    const handleOpenPrivacy = () => {
        setPrivacyOpen(true);
    };

    const handleClosePrivacy = () => {
        setPrivacyOpen(false);
    };

    return (
        <>
            {open && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        bgcolor: 'grey.900',
                        color: 'white',
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 3,
                        flexDirection: { xs: 'column', lg: 'row' },
                        zIndex: 9999,
                    }}
                >
                    <Typography variant="body1">
                        We collect user data ethically. By using this site, you agree to these terms!
                    </Typography>
                    <Box>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleAccept}
                            sx={{ mr: 2 }}
                        >
                            Accept
                        </Button>
                        <Button 
                            variant="text" 
                            color="inherit" 
                            onClick={handleOpenTerms}
                            sx={{ mr: 1 }}
                        >
                            Terms of Service
                        </Button>
                        <Button 
                            variant="text" 
                            color="inherit" 
                            onClick={handleOpenPrivacy}
                        >
                            Privacy Policy
                        </Button>
                    </Box>
                </Box>
            )}

            {/* Terms of Service Modal */}
            <Modal open={termsOpen} onClose={handleCloseTerms}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'grey.900',
                        color: 'white',
                        p: 4,
                        width: '80%',
                        maxWidth: 600,
                        boxShadow: 3,
                        borderRadius: 2,
                        overflowY: 'auto', // Enable scrolling for overflow content
                        maxHeight: '80vh', // Ensure the modal doesn't exceed viewport height
                        py: 10,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Terms of Service
                    </Typography>
                    <Typography variant="body2" paragraph>
                        Welcome to our website. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions:
                    </Typography>
                    <Typography variant="body2" paragraph>
                        1. **Data Collection**: We collect non-personally identifiable metadata such as browser type, device information, and network details. This data is used to enhance user experience and improve site functionality.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        2. **Google Analytics**: We use Google Analytics to track website usage and visitor trends. This tool collects data such as IP address, geographical location, and user behavior on our website. Google may store this data in accordance with their privacy policies.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        3. **Prohibited Use**: You agree not to misuse this website, including attempting to access unauthorized areas or distribute harmful code.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        4. **Disclaimer**: The content on this website is provided "as is." We make no guarantees about its accuracy, reliability, or suitability for your needs.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        5. **Changes to Terms**: We may update these terms from time to time. Continued use of the website constitutes acceptance of any changes.
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleCloseTerms}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>

            {/* Privacy Policy Modal */}
            <Modal open={privacyOpen} onClose={handleClosePrivacy}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'grey.900',
                        color: 'white',
                        p: 4,
                        width: '80%',
                        maxWidth: 600,
                        boxShadow: 3,
                        borderRadius: 2,
                        overflowY: 'auto', // Enable scrolling for overflow content
                        maxHeight: '80vh', // Ensure the modal doesn't exceed viewport height
                        py: 10,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Privacy Policy
                    </Typography>
                    <Typography variant="body2" paragraph>
                        Your privacy is important to us. This policy explains how we collect, use, and protect your data when you visit our website.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        1. **Information We Collect**: We collect metadata such as browser type, device information, screen size, operating system, and network details. This information is collected automatically and does not include personally identifiable information.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        2. **Google Analytics**: We use Google Analytics to analyze visitor behavior and improve our website. Google may collect information such as your IP address, geographical location, and browsing behavior. For more information, please refer to Google’s [Privacy Policy](https://policies.google.com/privacy).
                    </Typography>
                    <Typography variant="body2" paragraph>
                        3. **Cookies**: We use cookies to enhance your experience and analyze website performance. You can disable cookies in your browser settings; however, this may affect site functionality.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        4. **Data Security**: We implement industry-standard measures to protect your data from unauthorized access or misuse. However, no method of transmission over the internet is 100% secure.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        5. **Third Parties**: We do not sell or share your data with third parties except as required for analytics, hosting, or legal compliance.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        6. **Your Rights**: Depending on your location, you may have rights under privacy laws such as GDPR or CCPA. For inquiries about your data, please contact us.
                    </Typography>
                    <Typography variant="body2" paragraph>
                        7. **Updates to this Policy**: We may update this privacy policy periodically. Any changes will be reflected on this page.
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleClosePrivacy}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default CookieBanner;
