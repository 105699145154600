import React, { useState, useRef } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';
import toolsData from '../components/NFTTools'; // Import the tools data
import { SmartTickerDraggable } from 'react-smart-ticker'; // Use draggable ticker

const ToolsShowcase = () => {
  const tickerRef = useRef();
  const [tools] = useState(toolsData);


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{ backgroundColor: '#121212', padding: '50px 0', color: '#fff' }}
    >
      <Container maxWidth={false}>
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{
            marginBottom: '40px',
            fontFamily: 'Crypto-Crash-Font',
            textShadow: '0px 0px 5px #FF00FF, 0px 0px 10px #00FFFF',
            filter: 'hue-rotate(10deg)',
            animation: 'glitchText 1.5s infinite',
            transition: 'filter 0.3s, text-shadow 0.3s',
          }}
        >
          Languages & Tools We Use
        </Typography>

        <SmartTickerDraggable
          forwardedRef={tickerRef}
          speed={50} // Adjust the speed for smooth scrolling
          infiniteScrollView={true} // Enable infinite scrolling
          style={{
            display: 'flex',
            gap: '20px', // Add spacing between cards
            padding: '20px 0',
          }}
        >
          {tools.map((tool, index) => (
            <motion.div
              key={index}
              style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#1e1e1e',
                  borderRadius: '15px',
                  boxShadow: '0 8px 20px rgba(0, 255, 255, 0.2)',
                  padding: '20px',
                  textAlign: 'center',
                  width: '300px',
                  height: '400px',
                  border: '2px solid rgba(255, 255, 255, 0.1)',
                  transition: 'transform 0.3s, border-color 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px) scale(1.05)',
                    borderColor: '#00FFFF',
                    boxShadow: '0 12px 30px rgba(0, 255, 255, 0.3)',
                  },
                }}
              >
                <Box
                  component="img"
                  src={tool.image}
                  alt={tool.name}
                  sx={{
                    width: 'auto',
                    height: '100px',
                    marginBottom: '20px',
                    filter: 'contrast(1.5) saturate(2) brightness(1.2)',
                    transition: 'filter 0.3s ease, transform 0.3s ease',
                    borderRadius: '10px',
                    border: '3px solid #00FFFF',
                    '&:hover': {
                      filter: 'contrast(2) saturate(2.5) brightness(1.5)',
                      transform: 'rotate(5deg) scale(1.1)',
                    },
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: '#fff',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {tool.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#bdbdbd',
                    marginBottom: '10px',
                    lineHeight: '1.5',
                    height: '60px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal',
                  }}
                >
                  {tool.description}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: '#bdbdbd',
                    fontStyle: 'italic',
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal',
                  }}
                >
                  <strong>Use Case:</strong> {tool.useCase}
                </Typography>
              </Box>
            </motion.div>
          ))}
        </SmartTickerDraggable>
      </Container>
    </motion.div>
  );
};

export default ToolsShowcase;
